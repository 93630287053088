import { f } from "vue-search-select";

export default {
    demo(){ console.log('hola desde core/session/demo') },  
    IngresoAccount(context, access){ context.commit('IngresoAccount', access); },  
    validToken(context){
      return new Promise((resolve, reject) => {
        window.masterApiv2.post('validarToken', 
          {
            user_id: localStorage.getItem('mark_client_data_m'),
            oauth_id: localStorage.getItem('oauth_m'),
          }
        ).then(response => {
          //console.log('bien desde el token de M');
          context.commit('IngresoAccount', true);
          window.emailVerify=response.data.emailVerify;
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error)
        });
      });
    },
    destroyToken(context) {
      console.log('logout');
      let c = context;
      return new Promise((resolve, reject) => {
        //debugger;
        window.master.post('logout', {
          user_id: localStorage.getItem('mark_client_data_m'),
          oauth: localStorage.getItem('oauth_m'),
        })
          .then(response => {
            console.log('salir');
            c.commit('destroyToken')
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            c.commit('destroyToken');
            reject(error);
          })
      });
    },
    logoutV2(context) {
      console.log('logout2');
      let c = context;
      return new Promise((resolve, reject) => {
        window.masterApiv2.post("logout2", {
          valid: localStorage.getItem('start')
        })
        .then(response => {
              console.log('salir');
              c.commit('destroyToken')
              resolve(response);
        })
        .catch(error => {
              console.log(error);
              c.commit('destroyToken');
              reject(error);
         });
       });     
    },
    retrieveToken(context, credentials) {
      //debugger;
      return new Promise((resolve, reject) => 
      {
        if(credentials.validM == true)
        {
            //login desde M
            let r = credentials;
            r.op = 1;
            if (localStorage.getItem('access_web') == '1'){ r.op = 2; }
            context.commit('set_valSessionAndHttpApis', r);
            resolve(true);
        }
        else{
          reject(false);
        }
      });
    },
    SET_EMAIL_VERIFY(context, verify){
      context.commit('setEmailVerify', verify);
    },
    privilegies_session(context){
      let user_id = window.localAccountID;
      let org_id = window.localorgdata;   
      return new Promise((resolve, reject) => {     
        if(org_id > 0)
        {
          let privilegies={ alls : false, modulos: [] };
          if(context.getters.getUserType==0)
          {
            privilegies.alls=true;
            context.commit('set_privilegies', privilegies);
            resolve(true);
          }
          else
          {
            window.master.get('privilegiessession/'+user_id+'/'+org_id)
            .then(response => {
              privilegies.modulos=response.data.result
              //console.log('pasando privilegios', privilegies);
              context.commit('set_privilegies', privilegies);
              resolve(true);
            })
            .catch(error => {
              console.log(error);
              resolve(false);
            });
          }
        }else{reject(false); }
      });
    },
    getDataSessionLogin(context)
    {
      // debugger;
      return new Promise((resolve, reject) => {  
        if(localStorage.getItem('getDataSession') && localStorage.getItem('mark_client_data')==JSON.parse(localStorage.getItem('getDataSession')).principal.id)
        {
          let r = JSON.parse(localStorage.getItem('getDataSession'));
          let privilegies={ alls : false, modulos: r.privilegios };
          if(window.user_type==0) { privilegies.alls=true; }
          context.commit('set_privilegies', privilegies);
          context.dispatch('setVarStorages', r);
          // console.log('localStorage getDataSessionLogin')
          resolve(r); 
        }
        else
        {
            window.master.post('getDataSessionLogin', {
                user_id: localStorage.getItem('master_client_data'),
                org_id : localStorage.getItem('mark_client_data'),
                UserType : localStorage.getItem('user_type'),
                AccountID : localStorage.getItem('mark_client_data_m'),
            })
            .then(response => {      
                // console.log('getDataSessionLogin', response.data.r)
                let r = response.data.r;
                //pasando privilegios
                let privilegies={ alls : false, modulos: r.privilegios };
                if(window.user_type==0) { privilegies.alls=true; }
                context.commit('set_privilegies', privilegies);
                context.dispatch('setVarStorages', r);
                // re-asignar valores al storage 
                localStorage.removeItem('getDataSession')
                localStorage.setItem('getDataSession', JSON.stringify(r));
                // servicio de SMS
                localStorage.removeItem('accountSMS')
                // cambiar este valor a siempre 2
                // localStorage.setItem('accountSMS', r.accountSMS);
                localStorage.setItem('accountSMS', 2);
                resolve(r); 
            })
            .catch(error => {
                console.log(error);
                reject(error); 
            });
        }
      });
    },
    setVarStorages(context, r){
      // console.log('setVarStorages');
      // ***** MODULE CORE/SESSION *****
      // informacion basica del usuario 
      context.commit('SET_DATAUSER', r.dataUser);  
      // ***** MODULE CORE/APPS *****
      // roles de usuairo
      context.commit('core/apps/SET_appRolUser', r.appRolUser, { root: true });           
      // Lista de app segun Org 
      let appList = { listApps: r.listApps };
      // llamando datos del state de core/session
      appList.privilegies = context.rootState.core.session.privilegies;
      appList.user_type = context.rootState.core.session.user_type;
      context.commit('core/apps/SET_APPLIST', appList, { root: true }); 
      // filtrar aplicaciones disponibles para instalar ( lista de listAvailableApps en HOME)
      context.commit('core/apps/SET_LISTAVAILABLEAPPS', r.allApps, { root: true }); 
      // ***** MODULE ORG *****
      // Org principal 
      context.commit('core/Org/SET_ORGINFO', r.principal, { root: true });
      // Lista de Orgs
      context.commit('core/Org/SET_ORG_All', r.orgList, { root: true });
      context.commit('core/Org/SET_ORG_All', r.orgList, { root: true });
      if(r.plan){
        window.OrgPlan = r.plan;
        context.commit('core/Org/setPlanId', r.plan, { root: true });
      }
      localStorage.setItem('statusBilling', r.statusBilling);
      context.commit('core/Org/setStatusBilling', r.statusBilling, { root: true });
    },
    registerSMS(context, register) {
      return new Promise((resolve, reject) => {
        window.masterApiv2.post('registerSMS', {
          user_id: register.user_id,
          phone: register.phone,
        })
        .then(response => {
          console.log('registerSMS', response.data);
          if(response.data.status){
            resolve(response.data);
          }else{
            console.log(response.data.error);
            resolve(response.data);
          } 
        })
        .catch(error => { 
          console.log(error);
          reject(false); 
        });
      });
    },
    sendSMSverify(context, credentials) {
      return new Promise((resolve, reject) => {
        window.masterApiv2.post('sendSMSverify', {
          email: credentials.email,
          typeU: credentials.typeU,
        })
        .then(response => {
          if(response.data.status){
            console.log('sendSMSverify', response.data);
            resolve(response.data);
          }else{
            console.log(response.data.error);
            resolve(response.data);
          } 
        })
        .catch(error => { 
          console.log(error);
          reject(false); 
        });
      });
    },
    confirmSMS(context, code) {
      return new Promise((resolve, reject) => {
        window.masterApiv2.post('confirmSMS', {
          code: code,
        })
        .then(response => {
            console.log('confirmSMS', response.data);
          if(response.data){
            resolve(response.data.status);
          }else{
            resolve(false);
          } 
        })
        .catch(error => { 
          console.log(error);
          reject(false); 
        });
      });
    },
    loginInterno(context, credentials) {
      //login
      return new Promise((resolve, reject) => {
        window.masterApiv2.post('loginInterno', {
          email: credentials.email,
          password: credentials.password,
        })
        .then(response => {
          if (response.data.warning === undefined) {
            if(response.data.status){
              context.commit('set_isLoading', true, { root: true }); // cargando preloar de login
              let r = response.data;
              r.op = 1;
              context.commit('set_valSessionAndHttpApis', r); // cargando preloar de login
              console.log('login Interno');
              resolve(true);
            }else{
              console.log(response.data.error);
              resolve(false);
            } 
          } else {
            console.log(response.data.error);
            let warning = 'warning'
            resolve(warning);
          }
        })
        .catch(error => { 
          console.log(error);
          reject(false); 
        });
      });
    },
    pushtetokenemail(context, credentials) {
      //login
      return new Promise((resolve, reject) => {
        window.masterApiv2.post('/account/pushtetokenemail', {
          email: credentials.email
        })
        .then(response => {
          let r = response.data;
          if(response.data.status){
            resolve(r);
          }else{
            console.log(response.data.error);
            resolve(r);
          } 
        })
        .catch(error => { 
          console.log(error);
          reject(false); 
        });
      });
    },
    validTypeUser(context) {
      return new Promise((resolve, reject) => {
        if(context.state.user_type == 0) {
          resolve(true); // usuario master
        } else { resolve(false); }
      });
    },
    sessionStorage(context) {
      return new Promise((resolve, reject) => {
        let valid = true;
        if(localStorage.getItem('access_token_m') == null || localStorage.getItem('access_token_m') == 'undefined') { valid = false; }
        if(localStorage.getItem('master_client_data') == null || localStorage.getItem('master_client_data') == 'undefined') { valid = false; }
        if(localStorage.getItem('mark_client_data_m') == null || localStorage.getItem('mark_client_data_m') == 'undefined') { valid = false; }
        if(localStorage.getItem('mark_client_data') == null || localStorage.getItem('mark_client_data') == 'undefined') { valid = false; }
        if(localStorage.getItem('user_type') == null || localStorage.getItem('user_type') == 'undefined') { valid = false; }
        if(localStorage.getItem('oauth_m') == null || localStorage.getItem('oauth_m') == 'undefined') { valid = false; }
        if(localStorage.getItem('accountSMS') == null || localStorage.getItem('accountSMS') == 'undefined') { valid = false; }
        if(localStorage.getItem('start') == null || localStorage.getItem('start') == 'undefined') { valid = false; }
        if(localStorage.getItem('access_web') == null || localStorage.getItem('access_web') == 'undefined') { valid = false; }
        if(localStorage.getItem('nomCompany') == null || localStorage.getItem('nomCompany') == 'undefined') { valid = false; }
        if(localStorage.getItem('getDataSession') == null || localStorage.getItem('getDataSession') == 'undefined') { valid = false; }
        resolve(valid);
      });
    },
    activation(context, credentials) {
      //activation
      return new Promise((resolve, reject) => {
        window.masterApiv2.post('activation', {
          code: credentials.code,
        })
        .then(response => {
          if(response.data.status){
            console.log(response.data);
            resolve(response); 
          }else{
            console.log(response.data.error);
            resolve(false);
          } 
        })
        .catch(error => { 
          console.log(error);
          reject(false); 
        });
      });
    },  
    actAppRol(context, data){   
        return new Promise((resolve, reject) => {    
            try {
                // re-asignar valores al storage 
                localStorage.removeItem('getDataSession');
                context.dispatch('getDataSessionLogin').then((r) => {
                    resolve(true);
                });
            } catch(e) {
                // statements
                resolve(false);
                console.log(e);
            } 
        });
    },
    validTokenSupportM(context, payload){
      return new Promise((resolve, reject) => {
        window.masterApiv2.get('getValidLoginAccessSupport/'+payload)
        .then(response => {
          console.log('getValidLoginAccessSupport', response.data);
          if(response.data.status){
            localStorage.setItem("access_token_m", response.data.token);
            localStorage.setItem("oauth_m", response.data.oauth);
            localStorage.setItem("master_client_data", response.data.master_user_id);
            localStorage.setItem("mark_client_data_m", response.data.user_id);
            localStorage.setItem("mark_client_data", response.data.org);
            localStorage.setItem("user_type", response.data.user_type);
            localStorage.setItem("start", response.data.start);
            context.commit('IngresoAccount', true);
            window.emailVerify=true;
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error)
        });
      });
    },
}
