<template>
	<div class="main_dash">
    <div class="row">
      <div class="col-12">
        <contentDisplay />
      </div>
    </div>
  </div>
</template>

<script>

import contentDisplay from '../components/ContentDisplay.vue';
export default {
  name: 'dashView',
  components: {
    contentDisplay,
  },
  data(){
    return {
    }
  },
};
</script>
<style scoped lang="scss">

</style>
