import lang_tutor from '@/apps/tutor/lang/';
import urlApis from '@/apps/tutor/apis/';
export default {
	lang_tutor: lang_tutor,
	app: false,
	urlApis: urlApis,
	access: false,
	asideStorage:[],
	selectedItem: null,
}
