<template>
  <div class="sidebar">
    <!-- <ul>
      <li v-for="item in filteredMenu" :key="item.id" @click="selectItem(item)" :class="{ active: selectedItem && selectedItem.id === item.id }">
        {{ item.title }}
      </li>
    </ul> -->
    <aside
            class="left-sidebar"
            data-sidebarbg="skin5"
            v-bind:class="{ min: activarLateral }"
        >
            <div class="scroll-sidebar">
              <input type="text" v-model="searchQuery" placeholder="Buscar por título o categoría" @input="filterResults" />
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li v-for="(i, idx) in filteredMenu" :key="idx" class="sidebar-item" >
                            <div
                                class="sidebar-link has-arrow waves-effect waves-dark"
                                v-on:click="activeSubMenu(i)"
                            >
                                <!-- <i :class="i.icon"></i> -->
                                <span class="hide-menu">{{ i.category }} </span>
                            </div>
                            <ul
                                class="collapse first-level"
                                v-bind:class="{ in: i.mActive }"
                                v-if="i.items.length > 0"
                            >
                                <li v-for="(item, index) in i.items" :key="index" class="sidebar-item"
                                :class="{ active: selectedItem && selectedItem.id === item.id }"
                                >
                                    <div class="sidebar-link" v-on:click="selectItem(item)">
                                        <span class="hide-menu">{{ item.title }}</span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <div class="btnAsideAction" @click="ShowHiddenAside">
                    <i
                        class="fas fa-chevron-left"
                        v-bind:class="{ min: activarLateral }"
                    ></i>
                </div>
            </div>
        </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  // props: {items: Array},
  data() {
    return {
      searchQuery: "",
      filteredMenu: [],
      selectedItem: null,
        activarLateral: false,
        winnerWidth: window.innerWidth,
        menuLateral: [
          {
            category: "Categoria A",
            catID: "1",
            mActive: true,
            items: [
              { id: 1, title: "Articulo 1", category: "Categoria A", catID: "1", content: "Contenido del Articulo 1" },
              { id: 2, title: "Articulo 2", category: "Categoria A", catID: "1", content: "Contenido del Articulo 2" },
              { id: 3, title: "Articulo 3", category: "Categoria A", catID: "1", content: "Contenido del Articulo 3" },
              { id: 4, title: "Articulo 4", category: "Categoria A", catID: "1", content: "Contenido del Articulo 4" },
            ],
          },
          {
            category: "Categoria B",
            catId: "2",
            mActive: true,
            items: [
              { id: 5, title: "Articulo 5", category: "Categoria B", catId: "2", content: "Contenido del Articulo 5" },
              { id: 6, title: "Articulo 6", category: "Categoria B", catId: "2", content: "Contenido del Articulo 6" },
              { id: 7, title: "Articulo 7", category: "Categoria B", catId: "2", content: "Contenido del Articulo 7" },
              { id: 8, title: "Articulo 8", category: "Categoria B", catId: "2", content: "Contenido del Articulo 8" },
            ],
          },
          {
            category: "Categoria C",
            catId: "3",
            mActive: true,
            items: [
              { id: 9, title: "Articulo 9", category: "Categoria C", catId: "3", content: "Contenido del Articulo 9" },
              { id: 10, title: "Articulo 10", category: "Categoria C", catId: "3", content: "Contenido del Articulo 10" },
              { id: 11, title: "Articulo 11", category: "Categoria C", catId: "3", content: "Contenido del Articulo 11" },
              { id: 12, title: "Articulo 12", category: "Categoria C", catId: "3", content: "Contenido del Articulo 12" },
            ],
          }
        ],
        submenu1: 0,
        submenu2: 0,
    };
  },
  methods: {
    ShowHiddenAside() {
        this.activarLateral = !this.activarLateral;
        this.$emit("activarLateral", this.activarLateral);
    },
    activeSubMenu(i) {
        let menuLateral = this.menuLateral
        for (const j in menuLateral) {
            if (menuLateral[j].mActive !== undefined) {
                if (menuLateral[j].mActive && menuLateral[j] != i) {
                    this.menuLateral[j].mActive = false;
                }
                if(menuLateral[j] == i) {
                    this.menuLateral[j].mActive = !this.menuLateral[j].mActive;
                }
            }
        }
    },
    filterResults() {
      const search = this.searchQuery.toLowerCase();

      // Filtrar categorías y sus items
      this.filteredMenu = this.menuLateral.map((category) => {
        const filteredItems = category.items.filter(
          (item) =>
            item.title.toLowerCase().includes(search) ||
            category.category.toLowerCase().includes(search)
        );

        // Actualizar el estado mActive
        return {
          ...category,
          mActive: filteredItems.length > 0,
          items: filteredItems,
        };
      });
    },
    selectItem(item) {
      this.$store.commit("ModApps/tutor/setSelectedItem", item);
    },
  },
  mounted() {
    this.filteredMenu = this.menuLateral;
  },
  computed: {
    ...mapGetters('ModApps/tutor', { getSelectedItem: 'selectedItem' }),
  },
  watch: {
    getSelectedItem: {
      handler: function (newVal) {
        this.selectedItem = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.left-sidebar {
    position: fixed;
    z-index: 100;
    padding-top: 42px;
    width: 220px;
}
nav.sidebar-nav {
    height: calc(100% - 80px);
    overflow: hidden;
    overflow-y: auto;
}
.sidebar-nav ul .sidebar-item {
  width: 220px;
}
.btnAsideAction {
    position: absolute;
    bottom: -10px;
    top: initial;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #343435;
    cursor: pointer;
    padding: 10px 4px 4px 4px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}
aside.left-sidebar.min {
    width: 55px;
}
aside.left-sidebar.min .scroll-sidebar {
    height: 100%;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction {
    bottom: 0px;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction i:before {
    content: "\f054";
}
aside.left-sidebar.min .scroll-sidebar nav.sidebar-nav ul#sidebarnav li.sidebar-item,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level
    li.sidebar-item {
    width: 55px;
    transition: all 0.5s ease-out;
    position: relative;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in {
    position: absolute;
    top: 0px;
    left: 40px;
    width: 170px;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link {
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    div.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level.in {
    display: block;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link
    span.hide-menu {
    display: block;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link {
    border-left: 2px solid transparent;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    a.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark.active {
    border-left: 2px solid #00b04b;
    opacity: 1;
    background-color: #555;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level {
    background: #191919;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    transition: all 0.5s ease-out;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    -webkit-transform: rotate(135deg) translate(0, -50%) !important;
    transform: rotate(135deg) translate(0, -50%) !important;
}
a.sidebar-link.waves-effect.waves-dark {
    display: flex;
    flex-direction: column;
}
.sidebar-nav ul .sidebar-item .sidebar-link i {
    line-height: 10px;
}
.sidebar-nav > #sidebarnav > .sidebar-item > .active.router-link-active {
    border-left: 2px solid #00b04b;
    color: #fff;
    opacity: 1;
    background-color: #555;
}
#main-wrapper[data-layout="vertical"]
    .left-sidebar[data-sidebarbg="skin5"]
    .sidebar-nav
    > #sidebarnav
    > .sidebar-item
    > .sidebar-link:hover,
#main-wrapper[data-layout="horizontal"]
    .left-sidebar[data-sidebarbg="skin5"]
    .sidebar-nav
    > #sidebarnav
    > .sidebar-item
    > .sidebar-link:hover {
    border-left: 2px solid #00b04b;
}
span.hide-menu {
    margin-top: 0px;
    font-size: 16px;
}
.sidebar-nav ul .sidebar-item .sidebar-link {
    padding: 12px 0px 8px 0px;
    line-height: 20px;
}
@media (max-width: 1024px) {
    .sidebar-nav ul .sidebar-item .sidebar-link {
        padding: 12px 0px 8px 0px;
    }
}
/** */
.sidebar {
  padding: 10px 10px;
  margin-top: 50px;
}

input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}

</style>
