import TutorLayout from '../Layouts/TutorLayout.vue'
import dashView from '../views/dashView.vue'
const nameApp = 'Tutor';
export default [
	{
		path: '/tutor',
		folder: TutorLayout,
		meta: {requiresAuth: true},
		children: [
			{
				path: '/',
				name: 'Tutor',
				folder: dashView
			},
		]
	}
]
